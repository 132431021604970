import {Component} from 'react';
import '../styles/navbar.css';
import TwinLogo from '../img/graphics/TWIN_Logo.png'



class NavbarMain extends Component{



  render(){
    return (
        <div className="navbarContainer">
          <div className="navbarLogo">
            <img src={TwinLogo} alt="Twin Logo" width="139" className="navbarLogo"  />
          </div>
          <div className='navbarLinks'>
                <a className="left-link" href="https://app.twinfinance.io" target="_blank" rel="noreferrer">
                  WEBAPP
                </a>
                &nbsp; &nbsp;
                <a className="center-link" href="https://twin-finance.medium.com" target="_blank" rel="noreferrer">
                  BLOG
                </a>
                &nbsp; &nbsp;
                <a className="right-link" href="https://docs.twinfinance.io/" target="_blank" rel="noreferrer">
                  DOCS
                </a>
          </div>
          
          <div className="navbarSocials">
            <a href="https://discord.gg/twinfinance" target="_blank"  rel="noreferrer" className='socials'>
              <div className='iconDiscord'></div>
            </a>
            <a href="https://github.com/TWIN-Protocol/" target="_blank"  rel="noreferrer" className='socials'>
              <div className='iconGithub'></div>
            </a>
            <a href="https://twitter.com/TwinFinance" target="_blank"  rel="noreferrer" className=''>
              <div className='iconTwitter'></div>
            </a>
          </div>
            
            
            
        </div>
    )
  } 
}

export default NavbarMain;